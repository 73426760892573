import React from 'react';

export default function IconWarning(props) {
  return (
    <svg width="40" height="40" fill="none" {...props}>
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
        fill="#000"
      >
        <path fill="#fff" d="M0 0h40v40H0z" />
        <path d="M30.9814 4.68159c2.3934 1.86098 4.3037 4.26841 5.5701 7.01961 1.2663 2.7513 1.8517 5.7658 1.7069 8.7898-.1952 4.65-2.0473 9.0777-5.223 12.4866-1.5937 1.6874-3.4799 3.0735-5.5678 4.0914-2.0957 1.0057-4.3581 1.6208-6.6755 1.8152-.5061.0439-1.0343.0732-1.5698.0732-2.5883.0106-5.1465-.5541-7.4889-1.653-2.34229-1.0989-4.40994-2.7044-6.05277-4.7001-3.05076-3.5647-4.712268-8.1066-4.68017-12.7939.0119-1.4285.17667-2.8516.49149-4.2452 1.1087-4.298 3.64243-8.09519 7.18897-10.77382 1.86778-1.38933 3.97318-2.42846 6.21338-3.06674 1.6658-.48421 3.3925-.728207 5.1276-.7246 2.7153-.002091 5.3977.59266 7.8565 1.74196 1.0983.53865 2.1381 1.1886 3.103 1.93959z" />
      </mask>
      <path
        d="M30.9814 4.68159c2.3934 1.86098 4.3037 4.26841 5.5701 7.01961 1.2663 2.7513 1.8517 5.7658 1.7069 8.7898-.1952 4.65-2.0473 9.0777-5.223 12.4866-1.5937 1.6874-3.4799 3.0735-5.5678 4.0914-2.0957 1.0057-4.3581 1.6208-6.6755 1.8152-.5061.0439-1.0343.0732-1.5698.0732-2.5883.0106-5.1465-.5541-7.4889-1.653-2.34229-1.0989-4.40994-2.7044-6.05277-4.7001-3.05076-3.5647-4.712268-8.1066-4.68017-12.7939.0119-1.4285.17667-2.8516.49149-4.2452 1.1087-4.298 3.64243-8.09519 7.18897-10.77382 1.86778-1.38933 3.97318-2.42846 6.21338-3.06674 1.6658-.48421 3.3925-.728207 5.1276-.7246 2.7153-.002091 5.3977.59266 7.8565 1.74196 1.0983.53865 2.1381 1.1886 3.103 1.93959z"
        fill="#F3F4F6"
      />
      <path
        d="M30.9814 4.68159c2.3934 1.86098 4.3037 4.26841 5.5701 7.01961 1.2663 2.7513 1.8517 5.7658 1.7069 8.7898-.1952 4.65-2.0473 9.0777-5.223 12.4866-1.5937 1.6874-3.4799 3.0735-5.5678 4.0914-2.0957 1.0057-4.3581 1.6208-6.6755 1.8152-.5061.0439-1.0343.0732-1.5698.0732-2.5883.0106-5.1465-.5541-7.4889-1.653-2.34229-1.0989-4.40994-2.7044-6.05277-4.7001-3.05076-3.5647-4.712268-8.1066-4.68017-12.7939.0119-1.4285.17667-2.8516.49149-4.2452 1.1087-4.298 3.64243-8.09519 7.18897-10.77382 1.86778-1.38933 3.97318-2.42846 6.21338-3.06674 1.6658-.48421 3.3925-.728207 5.1276-.7246 2.7153-.002091 5.3977.59266 7.8565 1.74196 1.0983.53865 2.1381 1.1886 3.103 1.93959z"
        stroke="#828A90"
        strokeWidth="2"
        mask="url(#a)"
      />
      <mask id="b" fill="#fff">
        <path d="M18.047 8.12756c.868-1.50342 3.038-1.50341 3.906.00001L32.6945 26.7324c.868 1.5034-.217 3.3827-1.953 3.3827H9.25849c-1.736 0-2.821-1.8793-1.953-3.3827L18.047 8.12756z" />
      </mask>
      <path
        d="M18.047 8.12756c.868-1.50342 3.038-1.50341 3.906.00001L32.6945 26.7324c.868 1.5034-.217 3.3827-1.953 3.3827H9.25849c-1.736 0-2.821-1.8793-1.953-3.3827L18.047 8.12756z"
        fill="#FDF5BF"
        stroke="#828A90"
        strokeWidth="2"
        mask="url(#b)"
      />
      <rect
        x="18.5"
        y="13.5"
        width="3"
        height="8"
        rx="1.5"
        fill="#F3F4F6"
        stroke="#828A90"
      />
      <circle cx="20" cy="25" r="1.5" fill="#F3F4F6" stroke="#828A90" />
    </svg>
  );
}
