import React from 'react';

export default function IconX(props) {
  return (
    <svg width="14" height="14" fill="none" {...props}>
      <path
        d="M9.04775 6.38021l-.24755.24747.2475.24753 2.9269 2.92723c.581.58106.5809 1.52306-.0001 2.10406s-1.5231.581-2.10415-.0001L6.94361 8.97935l-.24749-.24751-.2475.2475-3.24888 3.24886c-.58103.5811-1.52308.5811-2.10412 0-.581037-.581-.581037-1.5231 0-2.1041l3.18011-3.18011.24748-.24749-.24748-.24748-3.21449-3.21449c-.600024-.60002-.600023-1.57286 0-2.17289.60003-.600022 1.57287-.600023 2.17289 0l3.21449 3.21449.24744.24745.24749-.24741L10.125 1.09579c.581-.580878 1.5229-.580801 2.1039.00017.5811.5811.581 1.52328-.0002 2.10429L9.04775 6.38021z"
        fill="#828A90"
        stroke="#828A90"
        strokeWidth=".7"
      />
    </svg>
  );
}
